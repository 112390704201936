import { css, StyleSheet } from "aphrodite";
import React, { useState, useEffect } from "react";
import WPAPI from "wpapi";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import config from "./utils/config";
import getImages from "./utils/getImage";

const loader = async (id) => {
  const wp = new WPAPI({ endpoint: config.WP_API });

  const post = await wp.posts().id(id).get();
  return post;
};

export default function Certificates({ data, postId }) {
  const [modalShow, setModalShow] = useState(false);
  const [certificateTitle, setCertTitle] = useState("");
  const [image, setImage] = useState();
  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    Promise.all(getImages(data)).then(data => {
        setImagesArray(data);
    });
  }, [data]);

  const styles = StyleSheet.create({
    about: {
      padding: "0em",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      "@media (max-width: 600px)": {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    card: {
      margin: "2em 1em 2em 1em",
      "@media (max-width: 600px)": {
        margin: "0em",
        marginTop: "1em",
      },
    },
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const Cert = ({ cert }) => {
    return (
      <Carousel responsive={responsive}>
        {cert.map((image, id) => {
          return (
              <Card
                key={id}
                onClick={() => {
                  setModalShow(true);
                  setImage(image);
                }}
              >
                <Card.Img variant="top" src={image} />
              </Card>
          );
        })}
      </Carousel>
    );
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {certificateTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={image} fluid rounded />
        </Modal.Body>
      </Modal>
      <div
        style={{
          padding: "3em",
          display: "flex",
          flexDirection: "column",
          color: "rgba(15, 20, 36)",
          width: "100%",
        }}
      >
        <h3>Certifkáty</h3>
        <div className={css(styles.about)}>
          <Cert cert={imagesArray} />
        </div>
      </div>
    </>
  );
}
