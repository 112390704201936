import WPAPI from "wpapi";
import config from './config';
const wp = new WPAPI({ endpoint: config.WP_API });

function extractAllDataIdValues(str) {
  //const regex = /data-id="([^"]*)"/g;
  const regex = /class="wp-image-([^"]*)"/g;

  //class="wp-image-258"
  let matches;
  const values = [];

  while ((matches = regex.exec(str)) !== null) {
    values.push(matches[1]);
  }

  return values;
}

export default function getImages(point) {
  const imageIds = extractAllDataIdValues(point.content.rendered);
  const media = [];
  const data = imageIds.map(async (id) => {
    const img = await wp.media().id(id).get();
    //console.log("image", img);
    return (img.media_details.sizes.medium_large?img.media_details.sizes.medium_large.source_url:img.media_details.sizes.full.source_url);
  });
  return data;
}
