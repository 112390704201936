/* eslint-disable no-multi-str */
import { css, StyleSheet } from "aphrodite";
import React, { useState, useEffect } from "react";
import WPAPI from "wpapi";
import { Map, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";

import config from "./utils/config";
import getImages from "./utils/getImage";

import "./map.css";

import marker from "./assets/map/pin.svg";
import markerOrange from "./assets/map/pin_orange.svg";
import shadow from "./assets/map/marker-shadow.png";

function createMarkup(data) {
  return { __html: data };
}

const wp = new WPAPI({ endpoint: config.WP_API });

export const loader = async (id) => {
  const page = await wp.pages().id(id).get();
  let points = await wp.pages().parent(id).get();

  points = points.map((point) => {
    Promise.all(getImages(point)).then((data) => {
      point.images = data;
      point.id = point.id;
      point.title = point.title;
      point.place = { lat: point.acf.lat, lng: point.acf.long };
      point.description = point.content.rendered
        .replace(/\n/g, "")
        .replace(/<img\s*.*\/>/g, ""); //as html here
    });
    return point;
  });

  return { page, points };
};

export default function MapComponent({ pageId }) {
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const [activeProject, setActiveProject] = useState();
  const [page, setPage] = useState();
  const [media, setMedia] = useState([]);

  useEffect(() => {
    loader(pageId).then((data) => {
      if (projects.length === 0) {
        setProjects(data.points);
        setActiveProject(data.points[0]);
        setPosition({
          lat: data.points[0].acf.lat,
          lng: data.points[0].acf.long,
        });
        setImages(data.points[0].images);
      }
      if (!page) {
        setPage(data.page);
      }
    });
  }, [pageId, page, projects]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const styles = StyleSheet.create({
    about: {
      width: "100%",
      height: "auto",
      backgroundColor: "#E7421C", //rgba(15, 20, 36, 1)
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    highlight: {},
    map: {
      width: "100%",
      paddingTop: "3em",
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 1000px)": {
        display: "flex",
        flexDirection: "column",
        height: "auto",
      },
    },
    cardBox: {
      //width: '100em',
      flex: 2,
      minHeight: "500px",
      height: "700px",
      borderRadius: 0,
      //height: '100%',
      "@media (max-width: 600px)": {
        minHeight: "auto",
        height: "auto",
        width: "100%",
        //display: "none"
      },
    },
    card: {
      minHeight: "500px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 600px)": {
        minHeight: "auto",
        height: "auto",
        //display: "none"
      },
    },
    carouselStyle: {
      height: "450px",
      "@media (max-width: 600px)": {
        height: "100%",
      },
    },
    description: {
      "@media (max-width: 600px)": {
        //display: "none",
      },
    },
  });

  const pointerIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [30, 60],
    popupAnchor: [10, -44],
    iconSize: [60, 60],
    shadowUrl: shadow,
    shadowSize: [60, 60],
    shadowAnchor: [20, 60],
  });
  const pointerIconOrange = new L.Icon({
    iconUrl: markerOrange,
    iconRetinaUrl: markerOrange,
    iconAnchor: [30, 60],
    popupAnchor: [10, -44],
    iconSize: [60, 60],
    shadowUrl: shadow,
    shadowSize: [60, 60],
    shadowAnchor: [20, 60],
  });

  const markers = projects.map((project, index) => {
    if (project.place) {
      return (
        <Marker
          key={index}
          position={project.place}
          icon={index === active ? pointerIconOrange : pointerIcon}
          onClick={(event) => {
            setActive(index);
            setActiveProject(project);
            //setTitle(project.title);
            //setText(project.description);
            setPosition(project.place);
            const images = [];
            project.images.map((image) => {
              images.push(image);
              return image;
            });
            setImages(images);
          }}
        />
      );
    } else {
      return null;
    }
  });

  const Item = ({ images }) => {
    return (
      <Carousel activeIndex={index} onSelect={handleSelect} style={{ flex: 2 }}>
        {images.map((image, id) => {
          return (
            <Carousel.Item key={id} className={css(styles.carouselStyle)}>
              <Card.Img variant="top" src={image} style={{ height: "100%", width: "auto" }} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "3em",
        backgroundColor: "rgba(15, 20, 36, 0.05)",
      }}
    >
      <h3>{page ? page.title.rendered : ""}</h3>
      <div className={css(styles.map)}>
        {markers && activeProject ? (
          <>
            <Map center={position} zoom={11} maxZoom={14}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers}
            </Map>
            <div className={css(styles.cardBox)}>
              <Card className={css(styles.card)}>
                {activeProject.images ? <Item images={activeProject.images} /> : null}
                <Card.Body
                  style={{
                    backgroundColor: "#0F1424",
                    color: "white",
                    padding: "2em",
                  }}
                >
                  <Card.Title>
                    {activeProject.title.rendered?<h5>{activeProject.title.rendered}</h5>:""}
                  </Card.Title>
                  <Card.Text
                    dangerouslySetInnerHTML={createMarkup(
                      activeProject.description
                    )}
                    className={css(styles.description)}
                  />
                </Card.Body>
              </Card>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
