import { css, StyleSheet } from "aphrodite";
import React, { useEffect } from "react";
import Certificates from "./Certificates";
import Footer from "./Footer";

export default function Post({data}) {
  const styles = StyleSheet.create({
    highlightBox: {
      padding: "3em 1em 3em 3em",
      width: "100%",
      flex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      verticalAlign: "middle",
      fontSize: 25,
      "@media (max-width: 600px)": {
        padding: "1em 1em 1em 1em",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
    },
    highlight: {
      listStylePosition: "inside",
      padding: "0.2em",
      "@media (max-width: 600px)": {
        padding: "Opx",
        textAlign: "start",
      },
    },
    info: {
      padding: "3em 3em 3em 3em",
      width: "100%",
      flex: 3,
      backgroundColor: "white",
      color: "rgba(15, 20, 36, 1)",
      textAlign: "start",
      "@media (max-width: 600px)": {
        textAlign: "justify",
      },
    },
  });

  useEffect(() => {
    data.content.rendered.replace(/<img\s*.*\/>/g, (matched) => {
      matched.replace(/class="\s*.*"/g, 'class="responsive"');
    });
  }, [data]);

  const Post = ({id}) => {
    return (
      <div id={`${data.slug}`} key={id}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.content.rendered.replace(/\n/g, ""),
          }}
        />
      </div>
    );
  };

  return (
    // <div className={css(styles.about)}>

    <>
      {data && data.slug === "abstract" ? (
        <div className={css(styles.highlightBox)}>
          <Post id="abstract" />
        </div>
      ) : null}
      {data && data.slug === "about" ? (
        <div className={css(styles.info)}>
          <Post id="about"/>
        </div>
      ) : null}
      {data && data.slug === "cert" ? (
        <div className={css(styles.info)}>
          <Certificates id="cert" data={data}/>
        </div>
      ) : null}
      {data && data.slug === "kontakty" ? (
          <Footer data={data}/>
      ) : null}
    </>
  );
}
