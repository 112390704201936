import { css, StyleSheet } from "aphrodite";
import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import { Link, Element } from "react-scroll";
import WPAPI from "wpapi";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import title from "./assets/images/highlight.jpg";
import titleMobile from "./assets/images/highlight-mobile.jpg";
import logoDark from "./assets/images/logo-dark.svg";
import logo from "./assets/images/logo.svg";

import Menu from "./Menu";
import Post from "./Post";
import Map from "./Map";

import config from "./utils/config";

const wp = new WPAPI({ endpoint: config.WP_API });

const pageLoader = async () => {
  const pages = await wp.pages().perPage( 100 ).get();
  return pages;
};

const loader = async (pages) => {
  const menuList = await pages.then((data) => {
    const processedData = data
      .filter((item) => item.parent === 0)
      .map((item) => {
        return {
          id: item.id,
          title: item.title.rendered,
          link: `${item.slug}`,
        };
      });
    return processedData;
  });
  return menuList;
};

function App() {
  const translateKeyframes = {
    "0%": {
      transform: "translateX(100%)",
    },

    "100%": {
      transform: "translateX(0)",
    },
  };

  const opacityKeyframes = {
    from: {
      opacity: 0,
    },

    to: {
      opacity: 1,
    },
  };
  const styles = StyleSheet.create({
    app: {
      flex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 0,
      margin: 0,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.5em",
      margin: 0,
      backgroundColor: "#fff",
      width: "100%",
      height: "80px",
    },
    logo: {
      padding: "16px",
      width: "auto",
      height: "100%",
      cursor: "pointer",
    },
    title: {
      width: "100%",
      backgroundImage: `url(${title})`,
      backgroundColor: "grey" /* Used if the image is unavailable */,
      minHeight: "500px" /* You must set a specified height */,
      height: "70vh",
      backgroundPosition: "center" /* Center the image */,
      backgroundRepeat: "no-repeat" /* Do not repeat the image */,
      backgroundSize:
        "cover" /* Resize the background image to cover the entire container */,
      "@media (max-width: 600px)": {
        backgroundImage: `url(${titleMobile})`,
      },
    },
    overlay: {
      position: "relative",
      backgroundColor: "rgba(0,0,0,0.5)",
      width: "100%",
      height: "100%",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      ":hover": {
        //backgroundColor: 'rgba(0,0,0,0)',
      },
    },
    zippyHeader: {
      textTransform: "uppercase",
      position: "realtive",
      animationName: [opacityKeyframes],
      animationDuration: "3s",
      animationIterationCount: "one",
      fontSize: 60,
      textAlign: "justify",
      "@media (max-width: 600px)": {
        textAlign: "center",
      },
    },
    ul: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      display: "block",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    ulVertical: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
      marginTop: "5em",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
    },
    li: {
      float: "left",
      color: "#1e2a45",
      fontWeight: "bold",
      fontSize: 16,
      padding: "16px",
      ":hover": {
        color: "#E7421C",
      },
    },
    menu: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "rgba(255,255,255,0.98)",
      color: "rgba(30, 42, 69, 1)",
      width: "80%",
      height: "100%",
      zIndex: 100,
      animationName: [translateKeyframes, opacityKeyframes],
      animationDuration: "1s",
      animationIterationCount: "one",
      overflow: "hidden",
    },
    about: {
      width: "100%",
      height: "auto",
      backgroundColor: "#E7421C", //rgba(15, 20, 36, 1)
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      "@media (max-width: 600px)": {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
  });

  const [isShown, setIsShown] = useState(false);
  const [menu, setMenu] = useState(false);
  const [menuItems, setItems] = useState([]);
  const [posts, setPosts] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pages = pageLoader();

    loader(pages).then((data) => {
      if (menuItems.length === 0) {
        setItems(data);
        pages.then((page) => {
          setPages(page);
        });
      }
    });
  }, [menuItems]);

  const MenuElement = () => {
    return menuItems.map((element) => {
      if (element.title !== "Abstract") {
        return (
          <li key={element.id} className={css(styles.li)}>
            <Link
              activeClass="active"
              to={element.link}
              spy={true}
              smooth={true}
              duration={500}
            >
              {element.title}
            </Link>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const About = () => {
    return pages.map((data) => {
      if (data.slug === "about" || data.slug === "abstract") {
        return (
          <Element key={data.id} style={{ width: "100%" }}>
            <Post data={data}></Post>
          </Element>
        );
      } else {
        return null;
      }
    });
  };

  const Certificates = () => {
    return pages.map((element) => {
      if (element.slug === "cert") {
        return (
          <Element
            key={element.id}
            name={element.name}
            style={{ width: "100%" }}
          >
            <Post data={element}></Post>
          </Element>
        );
      } else {
        return null;
      }
    });
  };

  const MapElement = () => {
    return pages.map((element) => {
      if (element.slug === "map") {
        return (
          <Element
            key={element.id}
            name={element.slug}
            style={{ width: "100%" }}
          >
            <Map pageId={element.id}></Map>
          </Element>
        );
      } else {
        return null;
      }
    });
  };

  const Footer = () => {
    return pages.map((element, id) => {
      if (element.slug === "kontakty") {
        return (
          <Element
            key={element.id}
            name={element.slug}
            style={{ width: "100%" }}
          >
            <Post key={id} data={element}></Post>
          </Element>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={css(styles.app)}>
      <header className={css(styles.header)}>
        <div
          className={css(styles.logo)}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <Image
            src={isShown ? logo : logoDark}
            alt="Logo"
            width="auto"
            height="100%"
          />
        </div>
        <div style={{ display: "flex" }}>
          <ul className={css(styles.ul)}>
            <MenuElement />
          </ul>
          <Menu setMenu={setMenu} menu={menu}></Menu>
        </div>
      </header>
      <div
        className={css(styles.menu)}
        style={{ display: menu ? "block" : "none" }}
      >
        <ul className={css(styles.ulVertical)}>
          <MenuElement />
        </ul>
      </div>
      <div className={css(styles.title)}>
        <div className={css(styles.overlay)}>
          <h1 className={css(styles.zippyHeader)}>Stavíme s rozumem</h1>
        </div>
      </div>

      <Element name="about" id="about" style={{ width: "100%" }}>
        <div className={css(styles.about)}>
          <About />
        </div>
      </Element>
      <Element name="maps" id="maps" style={{ width: "100%" }}>
        <MapElement></MapElement>
      </Element>
      <Element name="cert" id="cert" style={{ width: "100%" }}>
        <Certificates></Certificates>
      </Element>
      <Element name="contact" id="contact" style={{ width: "100%" }}>
        <Footer></Footer>
      </Element>
    </div>
  );
}

export default App;
