import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import { PersonFill } from "react-bootstrap-icons";
import { Geo } from "react-bootstrap-icons";
import WPAPI from "wpapi";

import logo from "./assets/images/logoWhite.svg"; // Tell webpack this JS file uses this image

import config from "./utils/config";

const wp = new WPAPI({ endpoint: config.WP_API });

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    height: "auto",
    backgroundColor: "rgba(15, 20, 36)",
    color: "white",
    position: "relative",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    minHeight: "2em",
    padding: "3em 3em 1em 3em",
    "@media (max-width: 600px)": {
      minHeight: "2em",
      padding: "3em 3em 1em 3em",
    },
  },
  footerBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  title: {
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "uppercase",
    opacity: 0.5,
    fontWeight: 600,
  },
  content: {
    width: "100%",
    height: "100%",
    //backgroundColor: 'black',
    display: "flex",
    flexDirection: "row",
    paddingTop: "1em",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  contentPart: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    "@media (max-width: 600px)": {},
  },
  contentLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    textAlign: "center",
    width: "100%",
    "@media (min-width: 1024px)": {},
  },
  logo: {
    display: "block",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  address: {
    height: "100%",
    flex: 1,
    //backgroundColor: 'black',
    padding: "0.5em 0em 2em 0em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: "2em",
    "@media (max-width: 600px)": {
      padding: "Opx 0px 0px 0px",
      paddingRight: "0em",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  addressItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1em",
  },
  socialBox: {
    listStyleType: "none",
    margin: 0,
    padding: "2em 2em 0em 0em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  social: {
    display: "block",
    width: "34px",
    height: "34px",
    position: "relative",
    border: "1px solid #ffffff",
    borderRadius: "5px",
    paddingBottom: "2em",
    margin: "0em 1em 0em 1em",
  },
  p: {
    marginBottom: "0px !important",
  },
});

export const loader = async (id) => {
  const kontakty = await wp.pages().parent(id).get();
  return kontakty;
};

export default function Footer({ data }) {
  const [adresa, setAdresa] = useState("");
  const [kontakty, setKontakty] = useState([]);

  useEffect(() => {
    loader(data.id).then((data) => {
      const kontaktyList = [];
      data.forEach((item) => {
        if (item.slug !== "adresa") {
          kontaktyList.push(item);
        } else {
          //adresa
          setAdresa(item.content.rendered);
        }
      });
      setKontakty(kontaktyList);
    });
  }, [data]);
  return (
    <footer className={css(styles.footer)}>
      <div className={css(styles.footerBox)}>
        <div className={css(styles.content)}>
          <div
            className={css(styles.contentPart, styles.logo)}
            style={{ flex: 1 }}
          >
            <img
              src={logo}
              style={{ height: "3em", paddingTop: "0em" }}
              alt="Logo"
            />
          </div>
          <div className={css(styles.contentPart)} style={{ flex: 1 }}>
            <h3 className={css(styles.title)}>Kontakty</h3>
            <div className={css(styles.address)}>
              {kontakty.map((item, i) => {
                return (
                  <div key={i} className={css(styles.addressItem)}>
                    <PersonFill color="white" size={30} />
                    <p className={css(styles.p)} style={{ paddingTop: "1em" }}>
                      <b>{item.title.rendered}</b>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content.rendered.replace(/\n/g, ""),
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={css(styles.contentPart)} style={{ flex: 1 }}>
            <h3 className={css(styles.title)}>Adresa</h3>
            <div>
              <div className={css(styles.address)}>
                <div className={css(styles.addressItem)}>
                  <Geo color="white" size={30} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: adresa.replace(/\n/g, ""),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
