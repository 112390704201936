import React from 'react';
import './menu.css';


export default function Menu(props) {
  //const [isShown, setIsShown] = useState(false);
  /*useEffect(() => {
    console.log("here")
    document.body.style.overflow = 'hidden';
    return () => {
      console.log("herere")
      document.body.style.overflow = 'unset';
    };
  });*/
  return (
    <div  onClick={ () => {
      props.setMenu(!props.menu);
      !props.menu?document.body.style.overflow = 'hidden':document.body.style.overflow = 'unset';
    }} 
          className={!props.menu?"container":"change"}>
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  );
}
